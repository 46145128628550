import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function produceorderUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)



  // Table Handlers
  const tableColumns = [
    { key: 'produce_id', label: 'ID'},
    { key: 'produce_no', label: '指令编号' },
    //{ key: 'warehouse_id', label: '仓库ID' },
    { key: 'warehouse_name', label: '生产仓库' },
    //{ key: 'produce_type', label: '产线', sortable: true },
    //{ key: 'applier_id', label: '申请人ID', sortable: true },

    //{ key: 'receive_id', label: '接收人ID', sortable: true },
    //{ key: 'receive_name', label: '接收人', sortable: true },
    { key: 'purchase_qty', label: '计划数量' },
    { key: 'true_good_qty', label: '生产数量' },
    { key: 'true_last_date', label: '交货时间'},
    { key: 'applier_name', label: '申请人'},
    //{ key: 'true_bad_qty', label: '实际产量(不良)', sortable: true },
    //{ key: 'start_date', label: '期望日期', sortable: true },
    //{ key: 'purchase_last_date', label: '预计日期', sortable: true },
    //{ key: 'produce_require', label: '生产要求', sortable: true },
    //{ key: 'produce_quality', label: '质量要求', sortable: true },
    { key: 'memo', label: '运营备注', sortable: true },
    //{ key: 'attachments', label: '运营附件', sortable: true },
   // { key: 'memo_sales', label: '验收备注', sortable: true },
   // { key: 'attachments_sales', label: '验收附件', sortable: true },
   // { key: 'memo_op', label: '生产备注', sortable: true },
   // { key: 'attachments_op', label: '生产附件', sortable: true },
    { key: 'status', label: '生产状态 ' },
    //{ key: 'state', label: '启用', sortable: true },
    //{ key: 'create_time', label: '添加时间', sortable: true },
    //{ key: 'creator', label: '添加人ID', sortable: true },
   // { key: 'update_time', label: '更新时间', sortable: true },
    //{ key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('produce_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['produceorder/getCondition']}
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      produce_id:config.produce_id,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)


    store
      .dispatch('produceorder/search', params
      )
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '生产单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const produceList = (ctx, callback) => {
    const condition = {...store.getters['produceorder/getCondition']}
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      produce_id:config.produce_id,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    store
      .dispatch('produceorder/produce', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '生产单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    produceList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,


    refetchData,

    // Extra Filters
  }
}
